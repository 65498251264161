import { css } from '@emotion/react';
import { typo } from '@styles/typo.styles';
import { motion } from 'framer-motion';
import { useState } from 'react';

interface Props {
  question: JSX.Element;
  answer: JSX.Element;
}

export const FaqItem: React.FC<Props> = ({ question, answer }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <motion.li
      onClick={() => setExpanded(!expanded)}
      css={styles.faqItem}
      variants={{
        hidden: {
          opacity: 0,
          y: 20,
        },
        visible: {
          opacity: 1,
          y: 0,
          transition: {
            duration: 0.5,
            ease: [0.65, 0.01, 0.02, 0.98],
          },
        },
      }}
    >
      <motion.div css={styles.faqQuestion}>
        <span>{question}</span>
        <button css={styles.toggleButton}>
          {expanded ? (
            <motion.svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              animate={{ rotate: 0 }}
              transition={{
                duration: 0.1,
                ease: 'easeOut',
              }}
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="Minus">
                <path
                  id="Vector"
                  d="M2.5 8L13.5 8"
                  stroke="#B7BAB2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
            </motion.svg>
          ) : (
            <motion.svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              animate={{ rotate: 90 }}
              transition={{
                duration: 0.1,
                ease: 'easeOut',
              }}
            >
              <g id="e-add 1">
                <g id="Group">
                  <path
                    id="Union"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.5 1.5C8.5 1.22386 8.27614 1 8 1C7.72386 1 7.5 1.22386 7.5 1.5V7.5H1.5C1.22386 7.5 1 7.72386 1 8C1 8.27614 1.22386 8.5 1.5 8.5H7.5V14.5C7.5 14.7761 7.72386 15 8 15C8.27614 15 8.5 14.7761 8.5 14.5V8.5H14.5C14.7761 8.5 15 8.27614 15 8C15 7.72386 14.7761 7.5 14.5 7.5H8.5V1.5Z"
                    fill="#B7BAB2"
                  />
                </g>
              </g>
            </motion.svg>
          )}
        </button>
      </motion.div>
      <div
        onClick={() => setExpanded(!expanded)}
        css={expanded ? styles.answerExpanded : styles.answer}
      >
        <div>{answer}</div>
      </div>
      <div css={styles.border} />
    </motion.li>
  );
};

const styles = {
  border: css`
    width: auto;
    height: 1px;
    background-color: rgba(248, 248, 248, 0.08);
    margin-top: 32px;
    transition: background-color 0.2s ease-out;
  `,
  faqItem: css`
    display: flex;
    flex-direction: column;
    overflow: hidden;
    cursor: pointer;

    &:hover > div:last-of-type {
      background-color: var(--color-general-primary);
    }
  `,
  answer: css`
    ${typo.from16to14};
    color: var(--color-text-4);
    padding: 12px 30px 0 0;
    max-height: 0;
    transition: max-height 0.3s;
    overflow: hidden;
  `,
  answerExpanded: css`
    ${typo.from16to14};
    color: var(--color-text-4);
    padding: 12px 30px 0 0;
    transition: max-height 0.3s;
    overflow: hidden;
    max-height: 200px;
    transition: max-height 0.8s;
  `,
  faqQuestion: css`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    color: var(--color-text-6);
    font-size: clamp(1rem, 0.93rem + 0.32vw, 1.25rem);
    line-height: clamp(1.25rem, 1.18rem + 0.32vw, 1.5rem);
  `,
  toggleButton: css`
    float: right;
    cursor: pointer;
    background-color: transparent;
    border: none;
    padding: 0;
    width: 20px;
    height: 20px;
    position: relative;
  `,
};
