import { css } from '@emotion/react';
import { button as btn } from '@styles/button.styles';
import { grid } from '@styles/grid.styes';
import { typo } from '@styles/typo.styles';
import { breakpoints } from '@styles/variables.styles';

const base = css`
  width: 100%;
  display: flex;
  justify-content: center;
  background-image: url('/images/gradient.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  overflow: visible;
  margin: 80px 0 80px;

  @media ${breakpoints.fromLrg} {
    margin: 80px 0 194px;
  }
`;

const wrapper = css`
  ${grid.basic6col};
  margin: 0;

  @media ${breakpoints.fromLrg} {
    ${grid.basic12col};
  }
`;

const title = css`
  ${typo.from68to36};
  grid-column: 1 / span 6;
  color: var(--color-text-6);
  text-align: center;
  font-weight: 700;
  margin: 0 auto 20px;
  letter-spacing: -0.03em;
  width: 100%;
  max-width: 864px;

  & > span {
    justify-content: center;
  }

  @media ${breakpoints.fromLrg} {
    grid-column: 1 / span 12;
  }
`;

const present = css`
  ${typo.from16to14}
  grid-column: 1 / span 6;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  text-align: center;

  p {
    text-transform: uppercase;
    background: linear-gradient(
      90deg,
      rgba(169, 237, 49, 0.77) 15.78%,
      rgba(44, 238, 75, 0.77) 97.34%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    letter-spacing: 0.2rem;
  }

  @media ${breakpoints.fromLrg} {
    grid-column: 5 / span 4;
  }
`;

const paragraphWrapper = css`
  ${typo.from16to14};
  grid-column: 1 / span 6;
  display: flex;
  color: var(--color-text-5);
  margin: 0 auto 68px;
  text-align: center;
  max-width: 384px;

  & > p > span {
  }

  @media ${breakpoints.fromLrg} {
    grid-column: 5 / span 4;
    margin-bottom: 77px;
  }
`;

const image = css`
  position: relative;
  grid-column: 1 / span 6;
  overflow: hidden;
  display: flex;
  justify-content: center;
  width: 100%;
  height: auto;

  @media ${breakpoints.fromLrg} {
    grid-column: 1 / span 12;
  }
`;

const buttonWrapper = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  gap: 24px;

  @media ${breakpoints.from483} {
    flex-direction: row;
    justify-content: center;
  }
`;

const buttonContainer = css`
  display: flex;
  flex-wrap: wrap;
  margin-top: 48px;
  justify-content: center;
  gap: 12px;
  grid-column: 1 / span 6;
  width: 100%;

  @media ${breakpoints.fromLrg} {
    grid-column: 1 / span 12;
  }
`;

const contactButton = css`
  ${btn.primary}
  ${typo.from16to14}
  height: 48px;
  max-width: 200px;
  width: 100%;
  background: linear-gradient(89.8deg, #aeee39 -8.45%, #62f467 109.75%);
  font-family: var(--font-family-primaryBold);

  &:hover,
  &:focus {
    color: black;
    opacity: 1;
  }
`;

const infoButton = css`
  ${typo.from16to14}
  height: 48px;
  max-width: 200px;
  width: 100%;
  color: var(--color-text-5);
  font-family: var(--font-family-primarySemiBold);
  background: transparent;
  border: 1px solid;
  border-color: var(--color-border-cardBorder);
  border-radius: 32px;
  transition: all 0.15s ease-out;
  font-family: var(--font-family-primaryBold);
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    border-color: #f8f8f8f8;
    color: var(--color-text-6);
  }
`;

const gradient = css`
  background: linear-gradient(
    180deg,
    rgba(22, 20, 27, 0) 4.41%,
    #16141b 82.59%
  );
  position: absolute;
  width: 100%;
  height: 100px;
  bottom: 0;
  left: 0;
`;

export const styles = {
  base,
  title,
  present,
  paragraphWrapper,
  image,
  wrapper,
  buttonWrapper,
  buttonContainer,
  infoButton,
  contactButton,
  gradient,
};
